<template>
  <router-view></router-view>
</template>

<script>
import { ABILITY_TOKEN } from "@casl/vue";
import { fetchPermissions, fetchOUAccess } from "@/helpers/abilities.js";

export default {
  name: "App",
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  components: {},
  methods: {
    loadPermissions() {
      if (localStorage.getItem("jwt")) {
        let permissions = localStorage.getItem("permissions")
          ? JSON.parse(localStorage.getItem("permissions"))
          : [];
        if (
          permissions == undefined ||
          permissions == null ||
          permissions.length == 0
        ) {
          fetchPermissions();
        }
      }
    },
    loadOUAccess() {
      if (localStorage.getItem("jwt")) {
        let ouAccess = localStorage.getItem("ouAccess")
          ? JSON.parse(localStorage.getItem("ouAccess"))
          : [];
        if (ouAccess == undefined || ouAccess == null || ouAccess.length == 0) {
          fetchOUAccess();
        }
      }
    },
  },
  computed: {
    rules() {
      return this.$store.state.rules || [];
    },
  },
  created() {
    this.$store.commit("getPermissions");
    this.$store.commit("getRules");
    this.$ability.update(this.rules);
  },
  mounted() {
    this.loadPermissions();
    this.loadOUAccess();
  },
};
</script>

<style>
.introjs-tooltip-header {
  display: none !important;
}

.introjs-tooltip {
  width: 300px !important;
}

.introjs-tooltiptext {
  background-color: rgb(10, 179, 156) !important;
  color: #fff !important;
}

.introjs-tooltipbuttons {
  background-color: rgb(255, 255, 255) !important;
  color: #292929 !important;
}

.introjs-nextbutton {
  color: #ffffff !important;
  background-color: rgba(10, 179, 156) !important;
}

.introjs-prevbutton {
  border: 1px solid gray !important;
  background-color: rgb(255, 255, 255) !important;
  color: gray !important;
}

.introjs-tooltiptext {
  font-size: 15px !important;
}

.introjs-button,
.introjs-button:hover,
.introjs-button:focus,
.introjs-button:active,
.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
  outline: none !important;
  background-image: none !important;
  background-color: rgba(10, 179, 156);
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 0px !important;
  box-shadow: none !important;
  text-shadow: none !important;
}
</style>